// this should be moved under the Battlefield heading, it's all specifically for that code
import { getAuth } from 'firebase/auth';
import Cookies from 'js-cookie';
import { Store } from 'pullstate';
import { useEffect, useRef, useState } from 'react';
import { AppStoreType } from './AppStore';
import { ClientActions, ServerErrors, ServerNotifications } from './SocketEnums';
import { socket } from './SocketInit';
import { BattlefieldLocations, CardData, CardInPlay, Counter, DRAG_ZINDEX, DropReceivedEventData, Topics } from './types';

export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;

type DOMEvent = {
  clientX: number;
  clientY: number;
} & Record<keyof any, unknown>;

export const wasEventInsideRect = (mouseEvent: DOMEvent, element: any) => {
  return (mouseEvent.clientX >= element.getBoundingClientRect().left
    && mouseEvent.clientX <= element.getBoundingClientRect().right
    && mouseEvent.clientY >= element.getBoundingClientRect().top
    && mouseEvent.clientY <= element.getBoundingClientRect().bottom);
};


export const didMouseLeaveRect = (mouseEvent: DOMEvent, rect: any) => {
  return (mouseEvent.clientX >= rect.getBoundingClientRect().right
    || mouseEvent.clientX <= rect.getBoundingClientRect().left
    || mouseEvent.clientY >= rect.getBoundingClientRect().bottom
    || mouseEvent.clientY <= rect.getBoundingClientRect().top);
};


export const didMouseLeaveWindow = (leaveEvent: DOMEvent) => {
  return (leaveEvent.clientX >= document.body.offsetWidth
    || leaveEvent.clientX <= 0
    || leaveEvent.clientY >= document.body.offsetHeight
    || leaveEvent.clientY <= 0);
};


// stored reference to listener so it can be removed
let onMouseMoveReference_dragListener: any = null;
export const clearDragListener = () => {
  window.removeEventListener('mousemove', onMouseMoveReference_dragListener);
};


// set a global listener that attaches a callback to mouseMove, used for all dragging
export const setGlobalOnMouseMove = (
  mouseDownEvent: any,
  onMouseMoveCallback: (position: { x: number, y: number }, target?: any) => void,
  suppressCardDragEvent?: boolean,
) => {
  mouseDownEvent.preventDefault();
  clearDragListener();

  const onMouseMove = (mouseMoveEvent: any) => {
    mouseMoveEvent.preventDefault();
    const newMousePosition = {
      x: mouseMoveEvent.clientX,
      y: mouseMoveEvent.clientY,
    };
    // used for hover preview in CardCarousel
    if (!suppressCardDragEvent) {
      PubSub.publish(Topics.CardIsBeingDragged, newMousePosition);
    }
    onMouseMoveCallback(newMousePosition, mouseMoveEvent.target);
  };

  onMouseMoveReference_dragListener = onMouseMove;
  window.addEventListener('mousemove', onMouseMove);

  // for now, onWindowExit and onMouseUp handlers that clear this are set by the invoking code
  // (they use clearDragListener up above)
};


export const getCardDistanceFromWindowBottom = (
  newMouseY: number,
  mouseDistanceFromBottomOfCard: number
) => {
  const mouseDistanceFromWindowBottom = window.innerHeight - newMouseY;
  return mouseDistanceFromWindowBottom - mouseDistanceFromBottomOfCard;
};

// handles removing cards from their original location on a successful drop,
// or putting them back when dropped in an invalid place
export const removeCardsOrReturnToOriginalLocation = (
  mouseUpEvent: any,
  cameFrom: BattlefieldLocations,
  cards: CardData[],
  onSuccessfulDrop: any,
  onFailedDrop: any,
) => {
  const subscriptionTokens: string[] = [];
  const unsubscribeBoth = () => {
    subscriptionTokens.forEach(token => PubSub.unsubscribe(token));
  };
  // remove card from original location when it's successfully dropped elsewhere
  const onDroppedCardsReceivedHandler = (topic: string, dropReceivedData: DropReceivedEventData) => {
    onSuccessfulDrop(dropReceivedData);
    unsubscribeBoth();
  };
  // return card to original location when it's dropped in an invalid place
  const onInvalidDropLocationHander = () => {
    onFailedDrop();
    unsubscribeBoth();
  };

  subscriptionTokens.push(
    PubSub.subscribe(Topics.DroppedCardsReceived, onDroppedCardsReceivedHandler),
    PubSub.subscribe(Topics.InvalidDropLocation, onInvalidDropLocationHander)
  );

  PubSub.publish(Topics.CardsDropped, {
    mouseUpEvent,
    cameFrom,
    cards,
    left: mouseUpEvent.target.getBoundingClientRect().left,
    top: mouseUpEvent.target.getBoundingClientRect().top
  });
};


// if card would be positioned in a way that would overflow the edge of the playArea,
// offset its positioning such that it lines up against the edge instead.
// Third argument optional for alternative usage -- we either pass a mouseEvent or a cardRef.

// wait - now that everything is relative to the playArea, can we just get rid of this
// and use code like what we just added for counters, where we just set
// the percent position to 0 or 100 if they're beyond that?

export const getPlayAreaOffsetsForDropEvent = (
  mouseUpEvent: any,
  playAreaRef: any,
  cardRef?: any,
) => {
  const cardRect = mouseUpEvent ?
    mouseUpEvent.target.getBoundingClientRect()
     : cardRef.current.getBoundingClientRect();

  const playAreaRect = playAreaRef.getBoundingClientRect();
  let leftOffset = 0, topOffset = 0;

  if (cardRect.left < playAreaRect.left) {
    leftOffset = playAreaRect.left - cardRect.left;
  } else if (cardRect.right > playAreaRect.right) {
    leftOffset = playAreaRect.right - cardRect.right;
  }

  if (cardRect.top < playAreaRect.top) {
    topOffset = playAreaRect.top - cardRect.top;
  } else if (cardRect.bottom > playAreaRect.bottom) {
    topOffset = playAreaRect.bottom - cardRect.bottom;
  }

  return [leftOffset, topOffset];
};

export const getWindowOffsetsForMagnifiedCard = (cardRect: any) => {
  const windowRect = document.body.getBoundingClientRect();
  let leftOffset = 0, topOffset = 0;

  if (cardRect.left < windowRect.left) {
    leftOffset = windowRect.left - cardRect.left;
  } else if (cardRect.right > windowRect.right) {
    leftOffset = windowRect.right - cardRect.right;
  }

  if (cardRect.top < windowRect.top) {
    topOffset = windowRect.top - cardRect.top;
  } else if (cardRect.bottom > windowRect.bottom) {
    topOffset = windowRect.bottom - cardRect.bottom;
  }

  return [leftOffset, topOffset];
};


// used for converting absolute px values to percents for horizontal positioning
// WITHIN PLAY AREA ONLY (the leftInPixels arg should be relative to document)
export const getLeftPositionAsPercent = (leftInPixels: number, forOffset?: boolean) => {
  const playArea = document.querySelector('.playArea')!.getBoundingClientRect();
  const leftInPixels_withinPlayArea = leftInPixels - playArea.left;
  const responsiveLeft = leftInPixels_withinPlayArea / playArea.width * 100;
  return Math.round(responsiveLeft * 100) / 100;
};

// (not actually used currently)
// used for converting percent to absolute px values for horizontal positioning in the playArea
export const getLeftPositionAsAbsolute = (leftAsPercent: number) => {
  const docWidth = document.documentElement.getBoundingClientRect().width;
  const absolutePosition = leftAsPercent * docWidth / 100;
  return Math.round(absolutePosition * 100) / 100;
};


// used for converting absolute px values to percents for vertical positioning 
// WITHIN PLAY AREA ONLY (the topInPixels arg should be relative to document)
export const getVerticalPositionAsPercent = (topInPixels: number) => {
  const playArea = document.querySelector('.playArea')!.getBoundingClientRect();
  const topInPixels_withinPlayArea = topInPixels - playArea.top;
  const responsiveTop = topInPixels_withinPlayArea / playArea.height * 100;
  return Math.round(responsiveTop * 100) / 100;
};


// used for converting percent to absolute px when reading from backend
export const getVerticalPositionAsAbsolute = (positionAsPercent: number) => {
  const docHeight = document.documentElement.getBoundingClientRect().height;
  const absolutePosition = positionAsPercent * docHeight / 100;
  return Math.round(absolutePosition * 100) / 100;
};


// compare positions of two absolutely positioned elements to tell if they overlap
export const doElementsOverlap = (elementA: HTMLElement, elementB: HTMLElement) => {
  const A = elementA.getBoundingClientRect();
  const B = elementB.getBoundingClientRect();
  return (
    ((A.top < B.bottom && A.top >= B.top) // if A's upper portion overlaps B
    || (A.bottom > B.top && A.bottom <= B.bottom)  // if A's lower portion overlaps B
    || (A.bottom >= B.bottom && A.top <= B.top)) // if B is vertically inside A completely

    &&

    ((A.left < B.right && A.left >= B.left) // if A's left portion overlaps B
    || (A.right > B.left && A.right <= B.right)  // if A's right portion overlaps B
    || (A.left < B.left && A.right > B.right)) // if B is horizontally inside A completely
  );
};


// used to choose the next z-index value for playArea cards when you move or
// drop one and it needs to become the topmost card. skipId lets a card not get
// compared to itself once it's already the highest z-index, so that we don't 
// increment by 50 when dragging a card around.
export const getNextZIndex = (cardsInPlay: CardInPlay[], counters: Counter[], skipId?: string) => {
  let highest = 0;
  cardsInPlay.forEach(card => {
    if (skipId && card.id === skipId) {
      return;
    }
    // the 2billion check is to ignore the temporary value used when dragging
    if (card.zIndex > highest && card.zIndex < DRAG_ZINDEX) {
      highest = card.zIndex;
    }
  });

  counters.forEach(counter => {
    if (counter.zIndex > highest && counter.zIndex < DRAG_ZINDEX) {
      highest = counter.zIndex;
    }
  });

  return highest + 1;
};

// In a bunch of places where a card can be dropped, we need to get the data for that card
// while discarding extra properties in case it was a CardInPlay that was dropped
export const copyCardData = (cardData: CardData | CardInPlay): CardData => {
  return {
    id: cardData.id,
    name: cardData.name,
    url: cardData.url,
    showAltFace: cardData.showAltFace,
    altFaceUrl: cardData.altFaceUrl,
    gathererUrl: cardData.gathererUrl,
    cmc: cardData.cmc,
    type: cardData.type,
  };
};

export const preventRightClick = (clickEvent: any) => {
  clickEvent.preventDefault();
};

export const percentPixelsOfWindowHeight = (percent: number) => {
  var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  return (percent * h) / 100;
};

export const percentPixelsOfWindowWidth = (percent: number) => {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  return (percent * w) / 100;
};

// custom hook to keep track of a variable's value from the previous render
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T | null>(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// custom hook to force a component to update
export const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
};

export const authConnectOnLogin = async (
  appStore: Store<AppStoreType>,
  successHandler: () => void,
  errorHandler: () => void,
) => {
  const auth = getAuth();
  const authToken = await auth.currentUser!.getIdToken(true);
  setCookie('authToken', authToken);

  socket.emit(ClientActions.AUTH_CONNECT, { authToken }, async (response: any) => {
    if (response.error === ServerErrors.INVALID_TOKEN) {
      await auth.signOut();
      Cookies.remove('authToken');
      errorHandler();
    } else if (response.result === ServerNotifications.AUTH_SUCCESS) {
      setCookie('authToken', authToken);
      // Every 25 minutes, refresh the authToken cookie so that if the socket
      // connection lapses, it can just be re-auth'd without worrying about
      // the firebase token having expired. (It expires by default every 60 minutes,
      // and you can't change that.)
      appStore.update(s => {
        clearInterval(s.tokenRefreshingFn); // never let there be multiple of these
        s.tokenRefreshingFn = setInterval(async () => {
          const newAuthToken = await auth.currentUser!.getIdToken(true);
          setCookie('authToken', newAuthToken);
        }, ONE_MINUTE * 25);
        s.signedIn = true; // triggers other useEffect to load user profile w/decks
      });
      successHandler();
    }
  });
};

export const blurInputs = () => {
  if (document.activeElement) {
    (document.activeElement as HTMLElement).blur();
  }
};

export const vhToPixels = (value: number) => {
  const windowHeight = window.innerHeight;
  return (windowHeight * value) / 100;
};

// normally this would matter but because we're relying on firebase auth which uses
// indexedDB local storage rather than cookies, specifying domain actually has no effect
// at all on the authentication state distinction between cardtavern.com and www.cardtavern.com
export const setCookie = (key: string, value: string) => {
  const isLocal = document.location.hostname === 'localhost';
  if (isLocal) {
    Cookies.set(key, value);
  } else {
    Cookies.set(key, value, { domain: '.cardtavern.com' });
  }
};

export const useIsMobileDimensions = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 750);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};