import axios from 'axios';
import { Store } from 'pullstate';
import { AppStoreType } from '../../../../../../shared/AppStore';
import { ClientActions } from '../../../../../../shared/SocketEnums';
import { socket } from '../../../../../../shared/SocketInit';
import { DecklistItem, GameFormat } from '../../../../../../shared/types';

const isInteger = (value: string) => {
  return /^\d+$/.test(value);
};

const sleep = (ms: number) => {
  const startTime = new Date().getTime();
  while (new Date().getTime() - startTime < ms) {
    // Block execution
  }
};

type ParsedDecklist = {
  isEmptyLine: boolean;
  quantity: number;
  nameForQuery: string;
  lineNumber: number;
  originalLine: string;
}[];

export const createDeck = async (
  deckName: string,
  format: GameFormat,
  decklist: string,
  sideboard: string,
  setLoading: (enabled: boolean) => void,
  setError: (error: string | null) => void,
  setDecklist: (decklist: string) => void,
  setSideboard: (decklist: string) => void,
  appStore: Store<AppStoreType>,
) => {
  setError(null);

  if (decklist === '') {
    setError('Decklist cannot be empty.');
    return;
  };

  if (deckName.length > 50) {
    setError('Deck name cannot be longer than 50 characters.');
    return;
  }

  const parsedDecklist = validateAndParseInput(decklist, setError);
  const parsedSideboard = validateAndParseInput(sideboard, setError);

  if (!parsedDecklist) return;

  setLoading(true);

  const consolidatedDecklist = await fetchCardsForParsedList(
    parsedDecklist,
    setError,
    setDecklist,
  );

  let consolidatedSideboard;
  if (parsedSideboard) {
    consolidatedSideboard = await fetchCardsForParsedList(
      parsedSideboard,
      setError,
      setSideboard,
    );
  }

  setLoading(false);

  if (decklist && !consolidatedDecklist) return;
  if (sideboard && !consolidatedSideboard) return;

  socket.emit(ClientActions.CREATE_DECK, {
    deckName,
    format,
    decklist: consolidatedDecklist,
    sideboard: consolidatedSideboard || [],
  }, (response: any) => {
    if (response.error) {
      setError('Something went wrong while attempting to save your deck to the database.');
      setLoading(false);
    } else {
      socket.emit(ClientActions.LOAD_USER, (response: any) => {
        if (response.error) {
          appStore.update(s => {
            s.userData.error = 'Something went wrong while loading user data.';
          });
        } else {
          setError(null);
          setLoading(false);
          appStore.update(s => {
            s.createDeckDialog.isOpen = false;
            s.createDeckDialog.showSuccess = true;
            s.userData.decks = response.decks || [];
          });
        }
      });
    }
  });
};

export const fetchCardsForParsedList = async (
  parsedDecklist: ParsedDecklist,
  setError: (msg: string | null) => void,
  setDecklist: (decklist: string) => void,
) => {
  // This consolidates the decklist entries
  const consolidatedDecklist: ParsedDecklist = [];

  parsedDecklist.forEach(decklistItem => {
    if (decklistItem.isEmptyLine) {
      consolidatedDecklist.push(decklistItem);
      return;
    }

    const previousEntry = consolidatedDecklist.find(
      item => item.nameForQuery === decklistItem.nameForQuery
    );

    if (previousEntry) {
      previousEntry.quantity += decklistItem.quantity;
    } else {
      consolidatedDecklist.push(decklistItem);
    }
  });

  // convenience structure for getting the quantities faster in the loop below
  const quantitiesByCardName: { [name: string]: number } = {};
  consolidatedDecklist.filter(parsedLine => !parsedLine.isEmptyLine)
    .forEach(item => {
      quantitiesByCardName[item.nameForQuery.toLowerCase()] = item.quantity;
    });

  const decklistWithUrls: DecklistItem[] = [];
  
  // fetch card data from scryfall in batches of 75
  const nonEmptyDecklist = consolidatedDecklist.filter(parsedLine => !parsedLine.isEmptyLine);
  let batch = nonEmptyDecklist.slice(0, 75);
  let remainder = nonEmptyDecklist.slice(75);
  let notFound: { name: string }[] = [];

  do {
    try {
      const apiResponse: any = await axios.post(
        'https://api.scryfall.com/cards/collection', 
        {
          identifiers: batch.map(item => ({
            name: item.nameForQuery,
          })),
        },
      );

      const cardDetailsArray = apiResponse.data.data;
      notFound = notFound.concat(apiResponse.data.not_found);

      cardDetailsArray.forEach((responseItem: any) => {

        // This code divides a 2-faced card's name from 'Daybreak Ranger // Nightfall Predator'
        // into:
        // ['Daybreak Ranger // Nightfall Predator', 'Daybreak Ranger', ' // ', Nightfall Predator']
        // It then finds which of these names is the one the user originally wrote.
        // The REASON is so that we can find the quantity by matching the name with whatever
        // the user originally wrote.
        // (Because they can search by EITHER face or both at once via "face1 // face2")

        const responseName = responseItem.name;
        let nameQueriedBy = responseName;

        if (responseName.includes('//')) {
          let [fullName, mainFaceName, slashes, altFaceName] =
            (responseName.match(/(.*)( \/\/ )(.*)/) || [])
            .map((name: string) => removeSpecialCharactersAndSpaces(name.toLowerCase()));

          nameQueriedBy = parsedDecklist.find(parsedLine =>
            [fullName, mainFaceName, altFaceName].includes(parsedLine.nameForQuery.toLowerCase())
          )!.nameForQuery;
        }

        nameQueriedBy = splitAndCleanInputLine(nameQueriedBy);
        const quantity = quantitiesByCardName[nameQueriedBy.toLowerCase()];

        if (responseItem.image_uris) {
          decklistWithUrls.push({
            name: responseItem.name,
            quantity,
            url: responseItem.image_uris.normal,
            gathererUrl: responseItem.related_uris.gatherer,
            cmc: responseItem.cmc,
            type: responseItem.type_line,
            colors: responseItem.colors,
          });
        } else if (responseItem.card_faces) {
          decklistWithUrls.push({
            name: responseItem.name,
            quantity,
            url: responseItem.card_faces[0].image_uris.normal,
            altFaceUrl: responseItem.card_faces[1].image_uris.normal,
            gathererUrl: responseItem.related_uris.gatherer,
            cmc: responseItem.cmc,
            type: responseItem.type_line,
            colors: responseItem.card_faces[0].colors,
          });
        }
      });
    } catch (error) {
      console.log(error);
      setError('Something went wrong while fetching card details from Scryfall.');
      return;
    }

    batch = remainder.slice(0, 75);
    remainder = remainder.slice(75);

    // inject small latency rather than hitting scryfall api 2x in a row instantly,
    // per their usage guidelines
    if (batch.length > 0) {
      // console.log('sleeping');
      sleep(100);
    }
  } while (batch.length > 0);

  if (notFound.length > 0) {
    const problemLines: number[] = [];
    const namesNotFound = notFound.map(notFound => notFound.name);

    parsedDecklist.forEach(parsedLine => {
      if (namesNotFound.includes(parsedLine.nameForQuery)) {
        problemLines.push(parsedLine.lineNumber);
      }
    });

    let annotatedDecklistInput = '';

    parsedDecklist.forEach((parsedLine, i) => {
      if (problemLines.includes(i)) {
        annotatedDecklistInput = annotatedDecklistInput + 'X ' + parsedLine.originalLine + '\n';
      } else {
        annotatedDecklistInput = annotatedDecklistInput + parsedLine.originalLine + '\n';
      }
    });

    setDecklist(annotatedDecklistInput);
    setError('We couldn\'t find all the cards in your decklist. The problematic lines have been marked with an X. Check spelling and formatting.');
    return;
  }

  return decklistWithUrls;
};

export const validateAndParseInput = (
  decklist: string,
  setError: (msg: string | null) => void,
): ParsedDecklist | null => {
  decklist = decklist.trim();
  if (decklist === '') return null;

  const lines = decklist.split('\n');
  // this just divides it into the quantity and everthing that comes after the quantity
  const parsedLines = lines.map(s => s.split(/ (.+)/, 2));

  let parsedDecklist: ParsedDecklist = [];
  try {
    parsedDecklist = parsedLines.map((parsedLine, i) => {
      if (lines[i].trim() === '') {
        // preserve whitespace when validating so if they have it pasted in sections,
        // we don't mess that up when we display errors
        return {
          isEmptyLine: true,
          quantity: 0,
          nameForQuery: '',
          lineNumber: i,
          originalLine: lines[i],
        };
      } else {
        if (!isInteger(parsedLine[0])) {
          throw Error();
        }

        const nameForQuery = splitAndCleanInputLine(parsedLine[1]);

        return {
          quantity: +(parsedLine[0]),
          nameForQuery,
          lineNumber: i,
          originalLine: lines[i],
          isEmptyLine: false,
        };
      }
    });
  } catch (error) {
    setError('Decklist formatted incorrectly. Make sure every line is formatted like "3 Mountain", "1 Sol Ring", etc.')
    return null;
  }

  if (parsedDecklist.filter(parsedLine => !parsedLine.isEmptyLine).length > 300) {
    setError(`Decklist cannot contain more than 300 unique cards; currently contains ${parsedDecklist.length} unique cards.`);
    return null;
  }

  return parsedDecklist;
};

// This util identifies a set identifier at the end of a line and splits that off if present.
// The set identifier can either be in (), [], or <>, e.g. (MOM) [MOM] <MOM>
//
// Next, it takes either the whole string (if there was no set identifier) or everything
// that came before the set identifier and checks if it includes "//" for double-faced card
// syntax, and if so it cuts off everything from the slashes onward.
//
// Next it removes all special characters and spaces.
// We do this because scryfall will still correctly match cards with erroneous punctuation
// or whitespace, so we normalize by applying this util to both the user input
// and the scryfall response before comparing to figure out which line of input the response
// is a match for.
const splitAndCleanInputLine = (inputLine: string) => {
  // first we find anything like (MOM)/[FDE]/<ERE> at the end of the string and remove it
  const setSpecifierRegex = /(.*)([\(\[\{<].*[\)\]\}>])$/;
  const match = inputLine.trim().match(setSpecifierRegex);

  let result = inputLine, setSpecifier = '';
  if (match) {
    [, result, setSpecifier] = match;
  }

  // then, if it's a double faced cards listed like A // B, we reduce it to just A
  result = result.includes('//')
    ? result.split('//')[0].trim() : result;

  return removeSpecialCharactersAndSpaces(result);
};

const removeSpecialCharactersAndSpaces = (inputName: string) => {
  return removeAccents(inputName)
    // for all the cards that use Æther in their name
    .replace(/Æ/g, 'ae')
    // remove all special characters and whitespaces
    .replace(/[^a-zA-Z0-9]/g, '');
};

// This uses the normalize('NFD') method to decompose accented characters into a combination of the
// base character and the accent. Then, the replace() method with a regular expression /[\u0300-\u036f]/g
// is used to match and remove the combining diacritical marks. The regular expression [\u0300-\u036f]
// represents a character range from \u0300 to \u036f, which covers most diacritical marks used
//for accents in various languages.
// This is needed for cards like Lim-Dûl the Necromancer which would otherwise have the û stripped out as a special character.
const removeAccents = (input: string) => {
  return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const getDeckSizeForInput = (input: string): number => {
  const lines = input.split('\n');
  let sum = 0;

  for (const line of lines) {
    const match = line.match(/^(\d+)/); // Match digits at the start of the line

    if (match) {
      const number = parseInt(match[0]);
      sum += number;
    }
  }

  return sum;
};