import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { FaDiscord as DiscordLogo } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import { useForceUpdate } from '../../../../shared/utils';
import './LobbyNavigation.less';

const LobbyNavigation = () => {
  const activeStyle = ({ isActive }: any) => isActive ? 'activeNav' : undefined;
  const forceUpdate = useForceUpdate();

  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      forceUpdate();
    });
  }, []);

  return (
    <ul className="lobbyNavigation">
      <li>
        <NavLink to="/" className={activeStyle}>
          <HomeIcon/><span>Lobby</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/decks" className={activeStyle}>
          <DashboardCustomizeIcon/><span>My Decks</span>
        </NavLink>
      </li>

      {/* only display Account if logged-in */}
      {currentUser &&
        <>
          <li>
            <NavLink to="/account" className={activeStyle}>
              <AccountCircleIcon/><span>Account</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/settings" className={activeStyle}>
              <SettingsIcon/><span>Settings</span>
            </NavLink>
          </li>
        </>
      }

      <li>
        <a href="https://discord.gg/w85A8pCy7c" target="_blank">
          <DiscordLogo className="discordLogo"/> Discord
        </a>
      </li>
    </ul>
  );
};

export default LobbyNavigation;