import React, { useContext, useEffect, useState } from 'react';
import { GameStoreContext } from '../../../shared/GameStore';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import { cancelMagnification, setMagnifiedCardDetails } from '../../PlayArea/PlayAreaUtils';
import { UiStateStoreContext } from '../../../shared/UiStateStore';
import { CardData } from '../../../../../shared/types';
import './TransferList.less';

const areArraysEquivalent = (array1: any[], array2: any[]) => {
  // Sort the arrays to ensure order independence
  const sortedArray1 = sortBy(array1);
  const sortedArray2 = sortBy(array2);

  // Check if the sorted arrays are equivalent
  return isEqual(sortedArray1, sortedArray2);
};

type TransferListProps = {
  setHasChanges: (hasChanges: boolean) => void;
};

const TransferList = ({ setHasChanges }: TransferListProps) => {
  const gameStore = useContext(GameStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);

  const dialogIsOpen = uiStateStore.useState(s => s.sideboardDialog.isOpen);
  const privateCardData = gameStore.useState(s => s.privateCardData);

  const [decklist, setDecklist] = useState(privateCardData.currentDecklist);
  const [sideboard, setSideboard] = useState(privateCardData.currentSideboard);

  const deckSize = decklist.reduce((sum, item) => {
    return sum + item.quantity;
  }, 0);

  const handleItemClick = (item: any, isDecklist: boolean) => {
    const otherList = isDecklist ? sideboard : decklist;
    const currentList = isDecklist ? decklist : sideboard;
    const updatedCurrentList = cloneDeep(currentList);

    const selectedItemIndex = updatedCurrentList.findIndex(
      (listItem) => listItem.name === item.name
    );

    updatedCurrentList[selectedItemIndex].quantity -= 1;

    if (updatedCurrentList[selectedItemIndex].quantity === 0) {
      updatedCurrentList.splice(selectedItemIndex, 1);
      cancelMagnification(uiStateStore);
    }

    const updatedOtherList = otherList.map((listItem) => {
      if (listItem.name === item.name) {
        return {
          ...listItem,
          quantity: listItem.quantity + 1,
        };
      }
      return listItem;
    });

    if (!updatedOtherList.find(listItem => listItem.name === item.name)) {
      updatedOtherList.push({
        ...item,
        quantity: 1,
      });
    }

    if (isDecklist) {
      setDecklist(updatedCurrentList);
      setSideboard(updatedOtherList);
    } else {
      setDecklist(updatedOtherList);
      setSideboard(updatedCurrentList);
    }
  };

  useEffect(() => {
    setHasChanges(!areArraysEquivalent(sideboard, privateCardData.currentSideboard));
    uiStateStore.update(s => {
      s.sideboardDialog.workingDecklist = decklist;
      s.sideboardDialog.workingSideboard = sideboard;
    });

    // Something weird happens here where the useEffect (or useLayoutEffect!) is
    // executed BEFORE the dialog renders, and so finds no cardReferences
    // to add listeners to. I'm guessing MUI dialog has some stuff going on that
    // involves modifying the DOM post-render. Anyway, a 50ms timeout resolves the issue.
    setTimeout(() => {
      const decklistReferences = document.querySelectorAll('.decklistEntry');
      const sideboardReferences = document.querySelectorAll('.sideboardEntry');

      decklistReferences.forEach(cardReference => {
        cardReference.addEventListener('mouseenter', () => {
          const url = cardReference.getAttribute('data-url');
          const altFaceUrl = cardReference.getAttribute('data-altfaceurl');

          setMagnifiedCardDetails(
            { url, altFaceUrl } as CardData,
            { current: cardReference },
            uiStateStore,
            { noDelay: true },
          );
        });

        cardReference.addEventListener('mouseleave', () => {
          cancelMagnification(uiStateStore);
        });
      });

      sideboardReferences.forEach(cardReference => {
        cardReference.addEventListener('mouseenter', () => {
          const url = cardReference.getAttribute('data-url');
          const altFaceUrl = cardReference.getAttribute('data-altfaceurl');

          setMagnifiedCardDetails(
            { url, altFaceUrl } as CardData,
            { current: cardReference },
            uiStateStore,
            { noDelay: true, onLeft: true },
          );
        });

        cardReference.addEventListener('mouseleave', () => {
          cancelMagnification(uiStateStore);
        });
      });
    }, 50);
    // I originally thought we needed cleanup here to remove listeners, lest we end up
    // with thousands of duplicate listeners, but it doesn't work that way. I tested it
    // and setMagnifiedCardDetails is only ever called once per hover, no matter the number
    // of times this runs.
  }, [dialogIsOpen, sideboard]);

  return (
    <div className="transferListContainer">

      <div className="decklistContainer">
        <div className="listTitle">Decklist {`(${deckSize} cards)`}</div>
        <br/>
        <div className="scrollableContainer">
          <ul className="decklistContent">
            {decklist.map(decklistItem => (
              <li
                data-url={decklistItem.url}
                {...(decklistItem.altFaceUrl ? { 'data-altfaceurl': decklistItem.altFaceUrl } : {})}
                className="decklistEntry"
                key={decklistItem.name}
                onClick={() => handleItemClick(decklistItem, true)}
              >
                <span className="itemQuantity">{decklistItem.quantity}</span>
                <span className="itemName">{decklistItem.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="sideboardContainer">
        <div className="listTitle">Sideboard</div>
        <br/>
        <div className="scrollableContainer">
          <ul className="sideboardContent">
            {sideboard.map(decklistItem => (
              <li
                data-url={decklistItem.url}
                {...(decklistItem.altFaceUrl ? { 'data-altfaceurl': decklistItem.altFaceUrl } : {})}
                className="sideboardEntry"
                key={decklistItem.name}
                onClick={() => handleItemClick(decklistItem, false)}
              >
                <span className="itemQuantity">{decklistItem.quantity}</span>
                <span className="itemName">{decklistItem.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

    </div>
  );
};

export default TransferList;