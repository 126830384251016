import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Button } from '@mui/material';
import { Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountLinks from '../AccountLinks';
import { AppStore } from '../../../../../shared/AppStore';
import './MobileSidebar.less';

const MobileSidebar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const signedIn = AppStore.useState(s => s.signedIn);

  useEffect(() => {
    setShowMobileMenu(false);
  }, [signedIn]);

  return (
    <>
      <Button onClick={()=> setShowMobileMenu(!showMobileMenu)}>
        <MenuIcon/>
      </Button>
      <Drawer className="mobileSidebar" anchor="right" open={showMobileMenu} onClose={() => setShowMobileMenu(false)}>
        <a href="/" className="mobileMenuItem">
          Lobby
        </a>
        <a href="/decks" className="mobileMenuItem">
          My Decks
        </a>
        {currentUser &&
          <>
            <a href="/account" className="mobileMenuItem">
              Account
            </a>
            <a href="/settings" className="mobileMenuItem">
              Settings
            </a>
          </>
        }
        <a href="https://discord.gg/w85A8pCy7c" target="_blank" className="mobileMenuItem">
          Discord
        </a>
        <AccountLinks isForSidebar={true}/>
      </Drawer>
    </>
  );
};

export default MobileSidebar;