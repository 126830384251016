import React, { useState, useRef, useEffect, useContext } from 'react';
import { BattlefieldLocations, CardInPlay, ContextMenuInstanceSources, DRAG_ZINDEX, LEFT_MOUSE } from '../../../../../../shared/types';
import {
  clearDragListener,
  didMouseLeaveWindow,
  getPlayAreaOffsetsForDropEvent,
  getLeftPositionAsPercent,
  getVerticalPositionAsPercent,
  setGlobalOnMouseMove,
  wasEventInsideRect,
  getNextZIndex,
  usePrevious,
  removeCardsOrReturnToOriginalLocation
} from '../../../../../../shared/utils';
import BattlefieldApi from '../../../../utils/BattlefieldApi';
import { showContextMenu } from '../../../ContextMenu';
import { UiStateStoreContext } from '../../../../shared/UiStateStore';
import { CarouselStoreContext } from '../../../../shared/CarouselStore';
import { cancelMagnification, checkToShowTrespassingOverlay, nullifyUiStoreSelectionState, setMagnifiedCardDetails, updateTrespassingStatusForSingleCard } from '../../PlayAreaUtils';
import { GameStoreContext } from '../../../../shared/GameStore';
import PowerToughnessModifier from '../PowerToughnessModifier';
import './PlayAreaCard.less';

type PlayAreaCardProps = {
  card: CardInPlay;
  playAreaRef: any;
  setCardsInPlay: any; // (callback: (cardsInPlay: CardInPlay[]) => void) => void;
  myCardsInPlay: CardInPlay[];
};

let onCardLeaveReference: any = null;

const PlayAreaCard = React.memo(React.forwardRef(({
  card,
  playAreaRef,
  setCardsInPlay,
  myCardsInPlay,
}: PlayAreaCardProps, cardRef: any) => {
  const gameStore = useContext(GameStoreContext);
  const uiStateStore = useContext(UiStateStoreContext);
  const carouselStore = useContext(CarouselStoreContext);

  const dragSelectedCardIds = uiStateStore.useState(s => s.dragSelectedCards);
  // from each individual card's perspective, it is always one of the selected cards.
  // but we have to keep them separate too so we can tell when a non-drag-selected
  // card is clicked on, which deselects all the rest.
  const allSelectedCardIds = { ...dragSelectedCardIds, [card.id]: true };
  const isDragSelected = !!dragSelectedCardIds[card.id];

  const [isDragging, setIsDragging] = useState(false);
  const [mouseIsDown, setMouseIsDown] = useState(false);

  const mirrorMode = gameStore.useState(s => s.mirrorMode);

  // the 0,0 is arbitrary. it gets set to a new value before it's used.
  const initialMouseCoordinates = useRef({ x: 0, y: 0 });

  const counters = gameStore.useState(s => s.publicCounterData);

  const previousCardState = usePrevious(card);
  // animations for tap/untap actions and highlighting
  useEffect(() => {
    if (!previousCardState) return;
    if (previousCardState.tapped !== card.tapped) {
      if (card.tapped) {
        cardRef.current.classList.add('tapped');
      } else {
        cardRef.current.classList.remove('tapped');
      }
      cardRef.current.classList.add('transitioning');
      setTimeout(() => {
        cardRef.current.classList.remove('transitioning');
      }, 200);
    }

    if (card.isHighlighted && card.isHighlighted !== previousCardState.isHighlighted) {
      cardRef.current.classList.add('highlighted');
      setTimeout(() => {
        cardRef.current.classList.remove('highlighted');
      }, 400);
    }
  });

  const onMouseDown = (mouseDownEvent: any) => {
   // deselect everything when left- or right-clicking an unselected card
    if (!dragSelectedCardIds[card.id]) {
      nullifyUiStoreSelectionState(uiStateStore);
    }
    if (mouseDownEvent.button !== LEFT_MOUSE) return;

    setMouseIsDown(true);
    cancelMagnification(uiStateStore);
  };

  const onMouseUp = (mouseUpEvent: any) => {
    if (mouseUpEvent.button !== LEFT_MOUSE) return;
    // just because mouseUp was triggered doesn't mean we were dragging
    setMouseIsDown(false);
    if (!isDragging) return;
    mouseUpEvent.preventDefault();
    mouseUpEvent.persist();
    // remove windowExit listener when drag ends so there's never more than one
    cardRef.current.removeEventListener('mouseout', onCardLeaveReference);

    setIsDragging(false);
    clearDragListener();

    carouselStore.update(s => {
      s.activeCardOrigin = null;
      s.isDraggingCard = false;
    });

    const isNotOverDrawer = mouseUpEvent.clientY < document.querySelector('.slider')!.getBoundingClientRect().top;

    // update card location in playArea if it was just moved around there
    if (wasEventInsideRect(mouseUpEvent, playAreaRef) && isNotOverDrawer) {
      setCardsInPlay((cardsInPlay: CardInPlay[]) => {
        const newCardsInPlay = cardsInPlay.map(cardInPlay => {
          if (allSelectedCardIds[cardInPlay.id]) {
            const [leftOffset, topOffset] = getPlayAreaOffsetsForDropEvent(
              null,
              playAreaRef,
              cardInPlay.ref,
            );

            const cardRect = cardInPlay.ref.current.getBoundingClientRect();
            const tappedOffset = cardInPlay.tapped ? (cardRect.height - cardRect.width) / 2 : 0;
            const left = getLeftPositionAsPercent(cardRect.left + leftOffset - tappedOffset);
            const top = getVerticalPositionAsPercent(cardRect.top + topOffset + tappedOffset);

            // Timeout so that this goes out after the moveCardsWithinPlayArea call below.
            // it's hacky, but i couldn't think of the right way and got impatient.
            setTimeout(() => {
              const blockTrespassStart = Object.keys(allSelectedCardIds).filter(
                key => allSelectedCardIds[key]
              ).length > 1;
              updateTrespassingStatusForSingleCard(top, cardInPlay, uiStateStore, blockTrespassStart);
            }, 40);

            return {
              ...cardInPlay,
              left,
              top,
              originalLeft: left,
              originalTop: top,
              zIndex: cardInPlay.zIndex > DRAG_ZINDEX ?  // remove dragging offset if present
                cardInPlay.zIndex - DRAG_ZINDEX : cardInPlay.zIndex,
              isDragging: false,
            };
          }

          return cardInPlay;
        });

        BattlefieldApi.moveCardsWithinPlayArea(newCardsInPlay);

        return newCardsInPlay;
      });
    } else {
      removeCardsOrReturnToOriginalLocation(
        mouseUpEvent,
        BattlefieldLocations.PlayArea,
        myCardsInPlay.filter(cardInPlay => allSelectedCardIds[cardInPlay.id]),
        () => setCardsInPlay((cardsInPlay: CardInPlay[]) => {
          // remove cards from playArea on successful drop
          const filteredCards = cardsInPlay.filter(
            cardInPlay => !allSelectedCardIds[cardInPlay.id]
          );
          BattlefieldApi.updateTrespassers(Object.keys(allSelectedCardIds), false);
          // no other server call here because dropzone code takes care of saving interzone changes
          return filteredCards;
        }),
        () => {
          // return cards to original locations on failed drop
          setCardsInPlay((cardsInPlay: CardInPlay[]) => 
            cardsInPlay.map(cardInPlay => {
              if (allSelectedCardIds[cardInPlay.id]) {
                return {
                  ...cardInPlay,
                  left: cardInPlay.originalLeft,
                  top: cardInPlay.originalTop
                };
              }
              return cardInPlay;
            })
          );
        },
      );
    }
  };

  const onRightClick = (clickEvent: any) => {
    cancelMagnification(uiStateStore);

    const selectedCardsArray = myCardsInPlay
      .filter(cardInPlay => allSelectedCardIds[cardInPlay.id]);
    const selectedCardIdsArray = Object.keys(allSelectedCardIds);
    // If at least one card has another face, include the option to show it
    // in the context menu. only the two-faced card will be turned over, of course.
    const showTurnFaceUpMenuOption = selectedCardsArray.some(card => card.isFacedown);
    const showTurnFaceDownMenuOption = selectedCardsArray.some(card => !card.isFacedown);
    const showAltFaceMenuOption = selectedCardsArray.some(card => !!card.altFaceUrl)
      && !showTurnFaceUpMenuOption;

    showContextMenu(clickEvent, ContextMenuInstanceSources.PlayAreaCard,
      uiStateStore, {
        cardIds: selectedCardIdsArray,
        gathererUrl: card.gathererUrl,
        showAltFaceMenuOption,
        showTurnFaceUpMenuOption,
        showTurnFaceDownMenuOption,
        mirrorMode,
      });
  };

  const onMouseEnter = (mouseEnterEvent: any) => {
    if (isDragging) return;
    if (uiStateStore.getRawState().isDragSelecting) return;

    if (!card.isFacedown || card.isRevealedToOwner) {
      setMagnifiedCardDetails(card, cardRef, uiStateStore);
    } else {
      uiStateStore.update(s => {
        s.keyboardShortcutTarget.hoveredCardId = card.id;
      });
    }
  };

  const onMouseLeave = (mouseLeaveEvent: any) => {
    cancelMagnification(uiStateStore);
    uiStateStore.update(s => {
      s.keyboardShortcutTarget.playAreaCursorPosition = null;
    });
  };

  const onMouseMove = (mouseMoveEvent: any) => {
    if (mouseIsDown && !isDragging) {
      setIsDragging(true);
      let selectedCards: CardInPlay[] = [];

      // set new values for zIndex, originalLeft, & originalTop for all selected cards
      setCardsInPlay((cardsInPlay: CardInPlay[]) => {
        // get all selected cards and sort them by z-index
        selectedCards = selectedCards.concat(cardsInPlay.filter(
          cardInPlay => allSelectedCardIds[cardInPlay.id]
        ).sort((a: CardInPlay, b: CardInPlay) => {
          if (a.zIndex > b.zIndex) {
            return 1;
          } else if (a.zIndex === b.zIndex) {
            return 0;
          } else return -1;
        }));


        // starting at the next z-index, assign all selected cards new
        // z-indices while maintaining their intra-group stacking order
        let nextZ = getNextZIndex(cardsInPlay, counters);
        const updatedSelectedCards = selectedCards.map(cardInPlay => ({
          ...cardInPlay,
          zIndex: nextZ++,
          isDragging: true,
        }));
        const unselectedCards = cardsInPlay.filter(
          cardInPlay => !allSelectedCardIds[cardInPlay.id]
        );
        return unselectedCards.concat(updatedSelectedCards);
      });

      carouselStore.update(s => {
        s.activeCardOrigin = BattlefieldLocations.PlayArea;
        s.isDraggingCard = true;
      });

      initialMouseCoordinates.current = {
        x: mouseMoveEvent.clientX,
        y: mouseMoveEvent.clientY,
      };
    
      // reset everything involving drag state if we move the mouse out of the window
      const onCardLeave = (leaveEvent: any) => {
        if (didMouseLeaveWindow(leaveEvent)) {
          setMouseIsDown(false);
          setIsDragging(false);
          clearDragListener();

          carouselStore.update(s => {
            s.activeCardOrigin = null;
            s.isDraggingCard = false;
          });

          // return cards to original locations
          setCardsInPlay((cardsInPlay: CardInPlay[]) => 
            cardsInPlay.map(cardInPlay => {
              if (allSelectedCardIds[cardInPlay.id]) {
                return {
                  ...cardInPlay,
                  left: cardInPlay.originalLeft,
                  top: cardInPlay.originalTop,
                  isDragging: false,
                };
              }
              return cardInPlay;
            })
          );
          cardRef.current.removeEventListener('mouseout', onCardLeaveReference);
        }
      };
      cardRef.current.addEventListener('mouseout', onCardLeave);
      onCardLeaveReference = onCardLeave;

      const playArea = document.querySelector('.playArea')!.getBoundingClientRect();

      setGlobalOnMouseMove(mouseMoveEvent, newMousePosition => {
        setCardsInPlay((cardsInPlay: CardInPlay[]) => {
          // if we're dragging just one selected card, we can trigger trespassing
          if (selectedCards.length === 1) {
            checkToShowTrespassingOverlay(selectedCards[0].ref.current, uiStateStore);
          }
          return cardsInPlay.map(cardInPlay => {
            if (allSelectedCardIds[cardInPlay.id]) {
              // This is hacky, but here's the explanation for the "+ playArea.left" (and "+ playArea.top").
              // The getLeft/TopPositionAsPercent functions both include a subtracted offset
              // to make up for being inside the position:relative playArea since they expect // the argument to be relative to the window.
              // Since in this case we're NOT giving an argument relative to the window, but
              // rather just a scalar delta value,
              // that subtracted offset should not be used. So we are adding the same value
              // to the argument in advance in order to just cancel it out.
              // At some point we should devise a cleaner scheme, maybe using a 2nd arg or
              // just a separate utility function that doesn't use that offset.
              const mouseXDelta = newMousePosition.x - initialMouseCoordinates.current.x + playArea.left;
              const mouseYDelta = newMousePosition.y - initialMouseCoordinates.current.y + playArea.top;

              const newCardLeft = cardInPlay.originalLeft + getLeftPositionAsPercent(mouseXDelta);
              const newCardTop = cardInPlay.originalTop + getVerticalPositionAsPercent(mouseYDelta);

              return {
                ...cardInPlay,
                left: newCardLeft,
                top: newCardTop,
                // Elevate above drawer while dragging, but only at the start.
                // We don't do this in onMouseDown because that would trigger when tapping/untapping
                zIndex: cardInPlay.zIndex < DRAG_ZINDEX ?
                  cardInPlay.zIndex + DRAG_ZINDEX : cardInPlay.zIndex,
              };
            }
            return cardInPlay;
          });
        });
      });
    } else {
      uiStateStore.update(s => {
        const x = mouseMoveEvent.clientX;
        const y = mouseMoveEvent.clientY;
        s.keyboardShortcutTarget.playAreaCursorPosition = [x, y];
      });
    }
  };

  const tapOrUntap = (e: any) => {
    if (!(e.detail % 2)) {
      setCardsInPlay((cardsInPlay: CardInPlay[]) => {
        const updatedCards = cardsInPlay.map(cardInPlay => {
          if (allSelectedCardIds[cardInPlay.id]) {
            return {
              ...cardInPlay,
              tapped: !cardInPlay.tapped
            };
          }
          return cardInPlay;
        });

        BattlefieldApi.moveCardsWithinPlayArea(updatedCards);

        // When we set it to updatedCards, for some reason it would stutter.
        // Easier to just leave it untouched on client and wait for state update to come in from server.
        return cardsInPlay;
      });
    }
  };

  // if it's been tapped since previous load, or is tapped on initial load,
  // display it in tapped state from the start with no animation.
  const tapped = !!(previousCardState?.tapped && card.tapped) ||
    (!previousCardState && card.tapped);

  return (
    <div
      className={`${tapped ? 'tapped' : ''} cardInPlay`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onContextMenu={onRightClick}
      onClick={tapOrUntap}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onDragStart={(e: React.MouseEvent) => {
        // This suddenly became necessary for Windows Chrome only sometime in 2024.
        // Without it, double-clicking a card to tap/untap and then attempting
        // to drag it results in the native drag initiating through this event,
        // which somehow interferes with our own DnD code here and breaks it.
        e.preventDefault();
      }}
      ref={cardRef}
      style={{
        left: card.left + '%',
        top: card.top + '%',
        zIndex: card.zIndex
      }}
    >
      <div className={`flipper ${card.isFacedown ? 'flipped' : ''}`}>
        <div className="front">
          <div className={`flipper ${card.showAltFace ? 'flipped' : ''}`}>
            <div className={`front ${isDragSelected ? 'groupSelectedCard' : ''}`} style={{ backgroundImage: `url("${card.url}")`}}/>
            {card.altFaceUrl &&
            <div className={`back ${isDragSelected ? 'groupSelectedCard' : ''}`} style={{ backgroundImage: `url("${card.altFaceUrl}")`}}/>}
          </div>
        </div>
        <div className={`back faceDownCardInPlay ${isDragSelected ? 'groupSelectedCard' : ''}`}/>
      </div>

      {card.showPTModifier &&
        <PowerToughnessModifier
          cardIds={
            myCardsInPlay
              .filter(cardInPlay => allSelectedCardIds[cardInPlay.id])
              .map(cardInPlay => cardInPlay.id)
          }
          powerModifier={card.powerModifier}
          toughnessModifier={card.toughnessModifier}
        />
      }
    </div>
  );
}));

export default PlayAreaCard;