import React from 'react';
import { Button } from '@mui/material';
import { DefaultKeyboardShortcuts, KeyboardActions, KeyboardShortcutLabels } from '../keyboardShortcutConstants';
import { UserSettings } from '../../../../shared/types';
import ChangeShortcutDialog from '../ChangeShortcutDialog';
import { AppStore } from '../../../../shared/AppStore';
import './KeyboardShortcutsTable.less';

type KeyboardShortcutsTableProps = {
  currentSettings: UserSettings['keyboardShortcuts'],
  setNewSettings: Function,
};

const KeyboardShortcutsTable = ({
  currentSettings,
  setNewSettings
}: KeyboardShortcutsTableProps) => {
  const resetDefaults = () => {
    setNewSettings({
      keyboardShortcuts: DefaultKeyboardShortcuts
    });
  };

  // convert ['shift', 'tab'] to 'Shift + tab' (for example)
  const getLabelForShortcut = (shortcutArray: string[]) => {
    if (shortcutArray.length === 1) {
      return shortcutArray[0].toUpperCase();
    }

    return shortcutArray.join(' + ').toUpperCase()
  };

  return (
    <div className="shortcutsTableContainer">
      <div className="shortcutsTableHeader">
        <h1>Keyboard Shortcuts</h1>
        <Button
          size="small"
          variant="contained"
          className="resetDefaultsButton"
          onClick={resetDefaults}
        >
          Reset to Defaults
        </Button>
      </div>

      <div className="shortcutsTable">
        <div className="columnLabels">
          <div>Action</div>
          <div>Key</div>
        </div>

        <div className="shortcutsTableContent">
          {Object.values(KeyboardActions).map((keyboardAction) => (
            <div
              className="keyboardShortcutRow"
              key={keyboardAction}
              onClick={() => {
                AppStore.update(s => {
                  s.changeShortcutDialog.isOpen = true;
                  s.changeShortcutDialog.action = keyboardAction;
                });
              }}
            >
              <div className="keyboardAction">
                {KeyboardShortcutLabels[keyboardAction]}
              </div>

              <div className="assignedShortcut">
                {getLabelForShortcut(currentSettings[keyboardAction])}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ChangeShortcutDialog setNewSettings={setNewSettings} />
    </div>
  );
};

export default KeyboardShortcutsTable;