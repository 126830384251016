import { Store } from 'pullstate';
import { CarouselStore } from '../../../shared/CarouselStore';
import { UiStateStore } from '../../../shared/UiStateStore';
import BattlefieldApi from '../../../utils/BattlefieldApi';
import { nullifyUiStoreSelectionState } from '../../PlayArea/PlayAreaUtils';

export const getOtherPlayerCardMenuOptions = (
  instanceData: {
    cardIds: string[];
    gathererUrl: string;
    mirrorMode: boolean;
  },
  carouselStore: Store<CarouselStore>,
  uiStateStore: Store<UiStateStore>,
) => {

  uiStateStore.update(s => {
    s.keyboardShortcutTarget.contextMenuTargetCardIds = instanceData.cardIds;
  });

  return [
    {
      text: 'Draw attention',
      action: () => {
        const selectedCards = instanceData.cardIds;
        BattlefieldApi.drawAttentionToCards(selectedCards);
      },
    },
    {
      text: 'View on Gatherer',
      action: () => {
        window.open(instanceData.gathererUrl, '_blank');
      },
      disabled: !instanceData.gathererUrl,
    },
    {
      text: 'Clone',
      action: () => {
        BattlefieldApi.cloneCards(instanceData.cardIds);
  
        nullifyUiStoreSelectionState(uiStateStore);
      },
      disabled: instanceData.mirrorMode,
    },
    {
      text: 'Request control',
      action: () => {
        BattlefieldApi.requestControlOfCards(instanceData.cardIds);
      },
      disabled: instanceData.mirrorMode,
    },
  ];
};