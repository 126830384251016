import { Dialog } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { DRAG_ZINDEX } from '../../../../../shared/types';
import { GameStoreContext } from '../../../shared/GameStore';
import { UiStateStoreContext } from '../../../shared/UiStateStore';
import TransferList from './TransferList';
import { Button } from '@mui/material';
import BattlefieldApi from '../../../utils/BattlefieldApi';
import './SideboardDialog.less';

const SideboardDialog = () => {
  const uiStateStore = useContext(UiStateStoreContext);
  const gameStore = useContext(GameStoreContext);

  const privateCardData = gameStore.useState(s => s.privateCardData);
  const { isOpen, workingDecklist, workingSideboard }  = uiStateStore.useState(s => s.sideboardDialog);

  const [hasChanges, setHasChanges] = useState(false);

  const sideboard = privateCardData.currentSideboard;

  return (
    <Dialog
      open={isOpen}
      onClose={() => uiStateStore.update(s => {
        s.sideboardDialog.isOpen = false;
      })}
      style={{ zIndex: DRAG_ZINDEX, maxWidth: 'unset' }}
      PaperProps={{
        className: 'sideboardDialog',
      }}
    >
      <div className="sideboardDialogContent">
        <br/>
        <div>
          Sideboard changes take effect when your cards are reset from the library menu.
        </div>
        <br/>
          {
            (!sideboard || sideboard.length === 0) ?
              'This deck\'s sideboard seems to be empty.'
              : <TransferList setHasChanges={setHasChanges} />
          }
        <br/>
        <div className="unsavedChangesContainer">
          <div>{hasChanges ? 'You have unsaved changes.' : 'No changes made.'}</div>
          <br/>
          <Button
            className="submitButton"
            disabled={!hasChanges}
            variant="contained"
            onClick={(e: any) => {
              BattlefieldApi.saveSideboardChanges(workingSideboard, workingDecklist, gameStore);
              uiStateStore.update(s => { s.sideboardDialog.isOpen = false; });
            }}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SideboardDialog;