import React from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { Button } from '@material-ui/core';
import { AppStore } from '../../../../../shared/AppStore';
import SignInDialog from '../SignInDialog';
import './AccountLinks.less';

type AccountLinksProps = {
  isForSidebar?: boolean;
}

const AccountLinks = ({ isForSidebar }: AccountLinksProps) => {
  const signedIn = AppStore.useState(s => s.signedIn);
  const auth = getAuth();

  const username = auth.currentUser?.displayName;

  const handleLogout = async () => {
    try {
      // (the logout endpoint response will clear the old authToken cookie)
      const authToken = await auth.currentUser?.getIdToken(true);
      await axios.post('/logout', { authToken });
      auth.signOut();
      AppStore.update(s => {
        clearInterval(s.tokenRefreshingFn);
        s.signedIn = false;
        s.tokenRefreshingFn = null;
      });
      window.location = '/' as any; // redirect to home upon logout
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;
    }
  };

  return (
    <>
      {signedIn ?
        <div className="signedInUsernameLabel">
          {!isForSidebar && `${username} ·`}
          <Button
            color="primary"
            className="logOutButton"
            onClick={handleLogout}
          >
            Log Out
          </Button>
        </div>
      :
        <Button
          color="primary"
          className="logInButton"
          onClick={() => {
            AppStore.update(s => { s.signInDialog.isOpen = true; });
          }}
        >
          Sign In
        </Button>
      }
      <SignInDialog/>
    </>
  );
};

export default AccountLinks;